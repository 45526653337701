<template>
    <div class="mobile-page-layout">
        <div class="mobile-page-header" style="border-bottom: 1px solid #e6e6e6;">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="mobile-page-header-text">
                        شاخص‌ها
                    </div>  
                </div>
                <!-- <div class="mobile-page-header-actions">  
                    <div class="header-actions-sw">
                        <div :class="'header-actions-sw-item ' + (typeList == 1 ? 'sw-item-active' : '')" @click="typeList = 1">فعلی</div>
                        <div :class="'header-actions-sw-item ' + (typeList == 2 ? 'sw-item-active' : '')" @click="typeList = 2">پیش‌بینی</div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="accordion-list">
            <ul>
                <li v-for="(item, index) in category_list" :key="index" :class="(Active_Accordion_List == index ? 'active-accordion-list' : '')">
                    <div class="accordion-list-title" @click="active(index)">
                        <i class="uil uil-folder folder-close"></i>
                        <i class="uil uil uil-folder-open folder-open"></i>
                        <span>{{ item.name }}</span>
                    </div>
                    <ul class="accordion-list-items">
                        <li v-for="(subitem, subindex) in item.items" :key="subindex">
                            <router-link :to="{ name: 'EconomicsCountryList', params: { category: item.slug , type: subitem.type },}">
                                {{subitem.name}}
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import category_list from "@/assets/static_country_list_category";

export default {
    name: 'CountryListCategoryView',
    component: {
    },
    data: function () {
        return {
            category_list,
            Active_Accordion_List :null,
            title: '',
        }
    },
    mounted() {},
    computed: {},
    methods: {
        active(id){
            if(this.Active_Accordion_List == id){
                this.Active_Accordion_List = null;
            }else{
                this.Active_Accordion_List = id;
            }
        }
    }
}
</script>
