const static_country_list_category = {
    // کار
    'job' : {
        name: 'کار',
        slug: 'job',
        items: [
            { type: 'unemployment-rate', name: 'نرخ بیکاری'},
            { type: 'employed-persons', name: 'افراد شاغل'},
            { type: 'employment-change', name: 'تغییر اشتغال'},
            { type: 'employment-rate', name: 'نرخ اشتغال'},
            { type: 'full-time-employment', name: 'استخدام تمام وقت'},
            { type: 'initial-jobless-claims', name: 'دریافت کنندگان بیمه بیکاری'},
            { type: 'job-vacancies', name: 'پست های خالی شغلی'},
            { type: 'labor-force-participation-rate', name: 'نرخ مشارکت نیروی کار'},
            { type: 'labour-costs', name: 'هزینه های کار'},
            { type: 'long-term-unemployment-rate', name: 'نرخ بیکاری بلند مدت'},
            { type: 'minimum-wages', name: 'حداقل دستمزد'},
            { type: 'non-farm-payrolls', name: 'آمار اشتغال غیر کشاورزی'},
            { type: 'part-time-employment', name: 'مدت زمان اشتغال'},
            { type: 'population', name: 'جمعیت'},
            { type: 'productivity', name: 'بهره وری'},
            { type: 'retirement-age-men', name: 'سن بازنشستگی مردان'},
            { type: 'retirement-age-women', name: 'سن بازنشستگی زنان'},
            { type: 'unemployed-persons', name: 'افراد بیکار'},
            { type: 'wage-growth', name: 'رشد دستمزد'},
            { type: 'wages', name: 'دستمزد'},
            { type: 'wages-in-manufacturing', name: 'دستمزد در تولید'},
            { type: 'youth-unemployment-rate', name: 'نرخ بیکاری جوانان'},
        ],
    },
    // دولت
    'government' : {
        name: 'دولت',
        slug:'government',
        items: [
            { type: 'government-debt-to-gdp', name: 'بدهی دولت به تولید ناخالص داخلی'},
            { type: 'asylum-applications', name: 'درخواست پناهندگی'},
            { type: 'fiscal-expenditure', name: 'هزینه های مالی'},
            { type: 'government-budget', name: 'بودجه دولت'},
            { type: 'government-budget-value', name: 'ارزش بودجه دولت'},
            { type: 'government-debt', name: 'بدهی های دولت'},
            { type: 'government-revenues', name: 'درآمدهای دولت'},
            { type: 'government-spending', name: 'هزینه های دولت'},
            { type: 'government-spending-to-gdp', name: 'مخارج دولت به تولید ناخالص داخلی'},
            { type: 'military-expenditure', name: 'مخارج نظامی'},
        ],
    },
    // قیمت ها
    'prices' : {
        name: 'قیمت ها',
        slug:'prices',
        items: [
            { type: 'inflation-rate', name: 'نرخ تورم'},
            { type: 'consumer-price-index-cpi', name: 'CPI قیمت مصرف کننده'},
            { type: 'core-consumer-prices', name: 'قیمت مصرف کننده اصلی'},
            { type: 'core-inflation-rate', name: 'اندازه اصل تورم'},
            { type: 'export-prices', name: 'قیمت صادرات'},
            { type: 'food-inflation', name: 'تورم مواد غذایی'},
            { type: 'gdp-deflator', name: 'ضریب تعدیل تولید ناخالص داخلی'},
            { type: 'harmonised-consumer-prices', name: 'قیمت مصرف کننده هماهنگ شده'},
            { type: 'import-prices', name: 'قیمت واردات'},
            { type: 'inflation-expectations', name: 'انتظارات تورم'},
            { type: 'inflation-rate-mom', name: 'نرخ تورم (ماهانه)'},
            { type: 'producer-prices', name: 'قیمت تولید'},
            { type: 'producer-prices-change', name: 'تغییر قیمت تولید کننده'},
        ],
    },
    // پول
    'money' : {
        name: 'پول',
        slug:'money',
        items: [
            { type: 'interest-rate', name: 'نرخ بهره'},
            { type: 'banks-balance-sheet', name: 'ترازنامه بانکی'},
            { type: 'cash-reserve-ratio', name: 'نسبت ذخیره نقدی'},
            { type: 'central-bank-balance-sheet', name: 'ترازنامه بانک مرکزی'},
            { type: 'deposit-interest-rate', name: 'نرخ بهره سپرده'},
            { type: 'foreign-exchange-reserves', name: 'ذخایر ارزی خارجی'},
            { type: 'interbank-rate', name: 'نرخ بهره بین بانکی'},
            { type: 'lending-rate', name: 'نرخ وام'},
            { type: 'loan-growth', name: 'میزان رشد وام'},
            { type: 'loans-to-private-sector', name: 'وام به بخش خصوصی'},
            { type: 'money-supply-m0', name: 'عرضه پول M0'},
            { type: 'money-supply-m1', name: 'عرضه پول M1'},
            { type: 'money-supply-m2', name: 'عرضه پول M2'},
            { type: 'money-supply-m3', name: 'عرضه پول M3'},
            { type: 'private-debt-to-gdp', name: 'بدهی های خصوصی به تولید ناخالص داخلی'},
        ],
    },
    // تجارت
    'trade' : {
        name: 'تجارت',
        slug:'trade',
        items: [
            { type: 'balance-of-trade', name: 'موازنه تجاری'},
            { type: 'capital-flows', name: 'گردش سرمایه'},
            { type: 'crude-oil-production', name: 'تولید نفت خام'},
            { type: 'current-account', name: 'حساب جاری'},
            { type: 'current-account-to-gdp', name: 'حساب جاری به تولید ناخالص داخلی'},
            { type: 'exports', name: 'صادرات'},
            { type: 'external-debt', name: 'بدهی خارجی'},
            { type: 'foreign-direct-investment', name: 'سرمایه گذاری مستقیم خارجی'},
            { type: 'gold-reserves', name: 'ذخایر طلا'},
            { type: 'imports', name: 'واردات'},
            { type: 'remittances', name: 'حواله'},
            { type: 'terms-of-trade', name: 'رابطه مبادله'},
            { type: 'terrorism-index', name: 'شاخص تروریسم'},
            { type: 'tourism-revenues', name: 'درآمد گردشگری'},
            { type: 'tourist-arrivals', name: 'ورود توریست'},
            { type: 'weapons-sales', name: 'فروش اسلحه'},
        ]
    },
    // تولید ناخالص داخلی
    'gdp-growth' : {
        name: 'تولید ناخالص داخلی',
        slug:'gdp-growth',
        items: [
            { type: 'gdp-growth-rate', name: 'نرخ رشد تولید ناخالص داخلی'},
            { type: 'gdp', name: 'تولید ناخالص داخلی'},
            { type: 'gdp-annual-growth-rate', name: 'نرخ رشد GDP سالانه'},
            { type: 'gdp-constant-prices', name: 'GDP به قیمت های ثابت'},
            { type: 'gdp-from-agriculture', name: 'GDP از کشاورزی'},
            { type: 'gdp-from-construction', name: 'GDP از ساخت و ساز'},
            { type: 'gdp-from-manufacturing', name: 'GDP از ساخت'},
            { type: 'gdp-from-mining', name: 'GDP از معادن'},
            { type: 'gdp-from-public-administration', name: 'GDP از اداره امور عمومی'},
            { type: 'gdp-from-services', name: 'GDP از خدمات'},
            { type: 'gdp-from-transport', name: 'GDP از حمل ونقل عمومی'},
            { type: 'gdp-from-utilities', name: 'GDP از خدمات رفاهی'},
            { type: 'gdp-per-capita', name: 'تولید ناخالص داخلی سرانه'},
            { type: 'gdp-per-capita-ppp', name: 'تولید ناخالص داخلی سرانه PPP'},
            { type: 'gross-fixed-capital-formation', name: 'تشکیل سرمایه ثابت ناخالص'},
            { type: 'gross-national-product', name: 'تولید ناخالص ملی'},
        ]
    },
    // مسکن
    'housing' : {
        name: 'مسکن',
        slug:'housing',
        items: [
            { type: 'building-permits', name: 'جواز ساختمان'},
            { type: 'construction-output', name: 'میزان ساخت و ساز'},
            { type: 'home-ownership-rate', name: 'نرخ مالکیت مسکن'},
            { type: 'housing-index', name: 'شاخص مسکن'},
            { type: 'housing-starts', name: 'خانه های مسکونی نوساز'},
            { type: 'price-to-rent-ratio', name: 'نسبت قیمت به اجاره'},
        ]
    },
    // تولید
    'production' : {
        name: 'تولید',
        slug:'production',
        items: [
            { type: 'bankruptcies', name: 'ورشکستگی'},
            { type: 'business-confidence', name: 'اطمینان کسب و کار'},
            { type: 'capacity-utilization', name: 'میزان استفاده از ظرفیت تولید'},
            { type: 'car-production', name: 'تولید خودرو'},
            { type: 'car-registrations', name: 'ثبت خودرو'},
            { type: 'cement-production', name: 'تولید سیمان'},
            { type: 'changes-in-inventories', name: 'تغییرات موجودی انبار'},
            { type: 'competitiveness-index', name: 'شاخص رقابت'},
            { type: 'competitiveness-rank', name: 'رتبه رقابت'},
            { type: 'composite-pmi', name: 'پی ام آی مرکب'},
            { type: 'corporate-profits', name: 'سود شرکت سهامی'},
            { type: 'corruption-index', name: 'شاخص فساد مالی'},
            { type: 'corruption-rank', name: 'رتبه فساد مالی'},
            { type: 'crude-oil-rigs', name: 'خطوط نفت خام'},
            { type: 'ease-of-doing-business', name: 'سهولت کسب و کار'},
            { type: 'electricity-production', name: 'تولید برق'},
            { type: 'factory-orders', name: 'سفارشات کارخانه'},
            { type: 'industrial-production', name: 'تولید صنعتی'},
            { type: 'industrial-production-mom', name: 'تولیدات صنعتی (ماهانه)'},
            { type: 'internet-speed', name: 'سرعت اینترنت'},
            { type: 'ip-addresses', name: 'آدرس های IP'},
            { type: 'leading-economic-index', name: 'شاخص اقتصادی مقدم'},
            { type: 'manufacturing-pmi', name: 'شاخص پی ام آی تولید'},
            { type: 'manufacturing-production', name: 'تولید صنعتی'},
            { type: 'mining-production', name: 'استخراج معدن'},
            { type: 'new-orders', name: 'میزان سفارشات جدید'},
            { type: 'services-pmi', name: 'شاخص پی ام آی خدمات'},
            { type: 'small-business-sentiment', name: 'تمایلات کسب و کار کوچک'},
            { type: 'steel-production', name: 'تولید فولاد'},
            { type: 'total-vehicle-sales', name: 'مجموع فروش خودرو'},
            { type: 'zew-economic-sentiment-index', name: 'شاخص تمایلات اقتصادی ZEW'},
        ]
    },
    // مصرف کننده
    'consumer' : {
        name: 'مصرف کننده',
        slug:'consumer',
        items: [
            { type: 'bank-lending-rate', name: 'نرخ وام بانکی'},
            { type: 'consumer-confidence', name: 'ضریب اطمینان مصرف کننده'},
            { type: 'consumer-credit', name: 'تسهیلات اعتباری خریدار'},
            { type: 'consumer-spending', name: 'هزینه های مصرف کننده'},
            { type: 'disposable-personal-income', name: 'درآمد قابل تصرف شخص'},
            { type: 'gasoline-prices', name: 'قیمت گازوئیل'},
            { type: 'households-debt-to-gdp', name: 'بدهی خانوار به تولید ناخالص ملی'},
            { type: 'households-debt-to-income', name: 'بدهی خانوار به درآمد'},
            { type: 'personal-savings', name: 'پس انداز های شخصی'},
            { type: 'personal-spending', name: 'هزینه های شخصی'},
            { type: 'private-sector-credit', name: 'اعتبار بخش خصوصی'},
            { type: 'retail-sales-mom', name: 'خرده فروشی (تغییرات ماهیانه)'},
            { type: 'retail-sales-yoy', name: 'خرده فروشی (تغییرات سالیانه)'},
        ]
    },
    // مالیات
    'tax' : {
        name: 'مالیات',
        slug:'tax',
        items: [
            { type: 'corporate-tax-rate', name: 'نرخ مالیات شرکت'},
            { type: 'personal-income-tax-rate', name: 'نرخ مالیات درآمد شخصی'},
            { type: 'sales-tax-rate', name: 'نرخ مالیات بر فروش'},
            { type: 'social-security-rate', name: 'نرخ تامین اجتماعی'},
            { type: 'social-security-rate-for-companies', name: 'نرخ امنیت اجتماعی برای شرکت'},
            { type: 'social-security-rate-for-employees', name: 'نرخ تامین اجتماعی برای کارکنان'},
        ]
    },
    // سلامتی
    'health' : {
        name: 'سلامتی',
        slug:'health',
        items: [
            { type: 'coronavirus-cases', name: 'موارد ویروس کرونا'},
            { type: 'coronavirus-deaths', name: 'مرگ و میر ویروس کرونا'},
            { type: 'coronavirus-vaccination-rate', name: 'میزان واکسیناسیون ویروس کرونا'},
            { type: 'coronavirus-vaccination-total', name: 'واکسیناسیون ویروس کرونا از کل'},
            { type: 'hospital-beds', name: 'تخت های بیمارستان'},
            { type: 'hospitals', name: 'بیمارستان ها'},
            { type: 'icu-beds', name: 'تخت های ICU'},
            { type: 'medical-doctors', name: 'پزشکان'},
            { type: 'nurses', name: 'پرستاران'},
        ]
    },
}

export default static_country_list_category;